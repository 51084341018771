@import 'styles/site.sass'

.Main
  position: relative
  border-radius: $radius-section-s $radius-section-s 0 0
  @include min($screen-m)
    border-radius: $radius-section-m $radius-section-m 0 0
  @include min($screen-l)
    border-radius: $radius-section-l $radius-section-l 0 0

  padding-top: $space-l
  // background: $grey-100
