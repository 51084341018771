@import 'styles/site'

.App
  padding: 0 0 0 0
  @include min($screen-m)
    padding-top: $border-m
  @include min($screen-l)
    padding-top: $border-l
    
  .content
    overflow-x: hidden
