@import 'styles/site.sass'

.Main
  position: relative
  
  @include min($screen-l)
    display: flex
    min-height: 80vh

.content
  position: relative
  @extend %round-section
  padding: $space-m $space-xs
  background: $black
  color: $peach-200
  display: flex
  align-items: center
  justify-content: center
  
  @include min($screen-l)
    flex: 1
    padding: $space-m
    
  .inner
    position: relative
    max-width: $text-max-width//bigUnits(14)

.image
  @include max($screen-l)
    height: 40vh
    width: 100%
  flex: 0.5
  @include cover-background(url(../images/pixel/p50.PNG))
